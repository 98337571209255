export const environment = {
  appUrl: 'https://activity-feed-ui.production.zonarsystems.net/',
  authEnv: 'production',
  name: 'production',
  adminRole: 'a0ae2891-38d5-4832-99bb-b1c6d883aa84',
  apiBase: 'https://api.zonarsystems.net',
  apiApigeePrefix: '/activity',
  devTools: false,
  zonarApps: {
    gtcxMapsBaseUrl: 'https://maps.zonarsystems.net',
    alertManagementBaseUrl: 'https://alertmanagement.zonarsystems.net'
  },
  alertManagementAdminConfigs: {
    roleId: 'a7c099f3-83fa-405f-abd5-2d89e0526d04',
    applicationId: '2c51e6ee-5b9a-48c8-b45e-da68991a4c27'
  },
  // TODO: Setup your application's authentication variables: see
  // https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  auth: {
    clientID: 'sxzqpzbitLy0ttXPpumBG62vgbHPZVw3',
    domain: 'zonar-login.auth0.com',
    audience: 'https://api.zonarsystems.net',
    // These come from the Core APIs as your application is built.
    applicationId: '239154b3-08e1-4e1e-8d2a-80553e4c9707',
    defaultZonarRole: '63356e3d-ca4d-4888-99c0-2907023bb7bb',
    alertManagementAppId: '2c51e6ee-5b9a-48c8-b45e-da68991a4c27',
    alertManagementRoleId: 'a7c099f3-83fa-405f-abd5-2d89e0526d04',
    useRefreshTokens: true
  },
  userPreferenceApiBase: {
    url: 'https://api.zonarsystems.net/alert/v1'
  },
  datadog: {
    applicationId: 'd9510c6e-b793-4156-abbd-c755919f1866',
    clientToken: 'pub7c67ecc6ecb6ec6a494008ed10faf740',
    siteUrl: 'us5.datadoghq.com'
  },
  devCycle: {
    clientId: 'dvc_client_99c9590c_3f43_4b61_a26b_6898f682f0d7_37976ef'
  },
  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },
  googleOptimize: {
    gOptId: null,
    powerOnAbTestId: null
  },
  production: true,
  region: 'NA',
  coreEntityApiBase: {
    url: 'https://api.zonarsystems.net/core/v1'
  },
  i18n: {
    supportedLanguages: ['en-US'],
    defaultLanguage: 'en-US'
  },
  featureFlags: {
    refreshActivityBtn: true
  }
};

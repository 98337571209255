import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';

import { DeviceDetectionService } from '../../services/device-detection.service';

@Component({
  selector: 'app-mobile-drawer',
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, MatSidenavModule, MatIconModule],
  templateUrl: './mobile-drawer.component.html',
  styleUrls: ['./mobile-drawer.component.scss']
})
export class MobileDrawerComponent {
  @ViewChild('mobileSidenav') mobileSidenav: MatSidenav;

  constructor(public media: DeviceDetectionService) {}

  openDrawer() {
    this.mobileSidenav.open();
  }

  closeDrawer() {
    this.mobileSidenav.close();
  }
}

<div class="container u-margin__top--medium" [ngClass]="{ 'hide-horizontal-scroll': device.mediaObserver.isActive('lt-md') }">
  <ng-container *ngIf="!device.mediaObserver.isActive('lt-md')">
    <mat-card class="u-margin__bottom--medium">
      <ng-container *ngTemplateOutlet="filtersBar"></ng-container>
    </mat-card>
  </ng-container>

  <ng-container *ngIf="device.mediaObserver.isActive('lt-md')">
    <ng-container *ngTemplateOutlet="filtersBar"></ng-container>
  </ng-container>
</div>

<ng-template #filtersBar>
  <span *ngIf="divisionList$ | withLoading | async as divisions">
    <ng-template [ngIf]="divisions.value">
      <zui-searchable-dropdown
        label="Locations"
        [options]="divisions.value"
        [isMultiple]="true"
        [z_formGroup]="filtersService.filtersForm"
        [selectOptionsControlName]="'divisions'"
        classIdentifier="driver-dropdown-select-panel"
        (selected)="onSelectedLocationFilter($event)"
        [ngClass]="{
          'u-margin__right--normal': !device.mediaObserver.isActive('lt-md'),
          'u-margin__bottom--normal dropdown-container': device.mediaObserver.isActive('lt-md')
        }"
      >
      </zui-searchable-dropdown>
    </ng-template>
    <ng-template [ngIf]="divisions.error"
      ><div class="dropdown-error-container u-margin__bottom--medium">Error loading divisions</div></ng-template
    >
    <ng-template [ngIf]="divisions.loading">
      <ngx-skeleton-loader
        count="1"
        [ngClass]="{
          'u-margin__right--normal': !device.mediaObserver.isActive('lt-md'),
          'u-margin__bottom--normal dropdown-container': device.mediaObserver.isActive('lt-md')
        }"
        [theme]="{
          'border-radius': '5px',
          height: '36px',
          width: '204px',
          'background-color': '#E3E3E3',
          'margin-top': '4px'
        }"
      ></ngx-skeleton-loader>
    </ng-template>
  </span>
  <zui-searchable-dropdown
    label="Status"
    [options]="statusList"
    [isMultiple]="true"
    [z_formGroup]="filtersService.filtersForm"
    [selectOptionsControlName]="'status'"
    classIdentifier="driver-dropdown-panel"
    (selected)="onSelectedStatusFilter($event)"
    [ngClass]="{
      'u-margin__right--normal': !device.mediaObserver.isActive('lt-md'),
      'u-margin__bottom--normal dropdown-container': device.mediaObserver.isActive('lt-md')
    }"
  >
  </zui-searchable-dropdown>
  <zui-searchable-dropdown
    label="Severity"
    [options]="severityList"
    [z_formGroup]="filtersService.filtersForm"
    [isMultiple]="true"
    [selectOptionsControlName]="'severity'"
    classIdentifier="driver-dropdown-panel"
    (selected)="onSelectedSeverityFilter($event)"
  >
  </zui-searchable-dropdown>
</ng-template>

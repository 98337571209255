import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivityAlertsService } from '@services/activity-alerts.service';
import { formatDate, getTimeAgoString } from '@shared/utilities';
import { HttpClient } from '@angular/common/http';
import { distinctUntilChanged, map, filter, concatMap, takeUntil, skip, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, merge, Observable, Subject } from 'rxjs';
import { ZonarUiNotificationsService } from '@zonar-ui/notifications';
import { ErrorCodes } from '@shared/constants/error-codes';
import { Store } from '@ngrx/store';
import { AppState } from '@app/app.state';
import { Company } from 'src/app/models/company.model';
import { cloneDeep } from 'lodash';
import { Alert } from '@app/models';
import { MyAlert, UnreadMyAlerts } from '@app/models/my-alerts.model';
import { MyAlertsService } from '@app/services/my-alerts.service';
import { FeatureToggleService } from '@app/services/feature-toggle.service';
import { AdminService } from '@app/services/admin.service';
import { ENV } from '@environments/environment.provider';
import { DeviceDetectionService } from '@app/services/device-detection.service';
import { FiltersService } from '@app/services/filters.service';
import { CompanyStoreService } from '@app/services/company-store.service';
import { DatadogService } from '@app/services/datadog.service';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';

@Component({
  selector: 'app-activity-feed',
  templateUrl: './activity-feed.component.html',
  styleUrls: ['./activity-feed.component.scss']
})
export class ActivityFeedComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();

  public isEndOfAlertsStream = false;
  public isEndOfMyAlertStream = false;
  public selectedOptions = {};

  isAdmin$ = this._adminService.isAnyAdmin$;
  alertManagementBaseUrl = this.env.zonarApps.alertManagementBaseUrl;

  from = 20;
  to = 40;

  fromMyAlert = 20;
  toMyAlert = 40;

  isHideUnreadMyAlerts = true;

  // If there are too few posts in the stream, the skeleton loader will remain on, even though
  // no load event has been triggered, because the user must scroll to check for new events.
  // To fix this, the number of alerts in the initially loaded alerts is checked against this
  // number and if it is fewer than this number, then the scroll event is disabled.
  // In an ideal future, this would be automatically calculated based on window height.
  MAX_NUMBER_OF_POSTS_FOR_AUTO_SCROLL_TRIGGER = 3;

  NUMBER_OF_ADDITIONAL_POSTS_TO_LOAD = 20;
  NUMBER_OF_ADDITIONAL_MY_ALERT_POSTS_TO_LOAD = 20;

  isClearingFilters = false;

  constructor(
    public activityAlertService: ActivityAlertsService,
    public myAlertService: MyAlertsService,
    public httpClient: HttpClient,
    private _notifications: ZonarUiNotificationsService,
    private _store: Store<AppState>,
    private _featureToggleService: FeatureToggleService,
    private _adminService: AdminService,
    public device: DeviceDetectionService,
    private _filtersService: FiltersService,
    @Inject(ENV) private env: any,
    private _companyService: CompanyStoreService,
    private _datadogService: DatadogService
  ) {}

  allAlerts$: BehaviorSubject<Array<Alert>> = new BehaviorSubject(null);
  allMyAlert$: BehaviorSubject<Array<MyAlert>> = new BehaviorSubject(null);
  unreadMyAlerts$: BehaviorSubject<UnreadMyAlerts> = new BehaviorSubject(null);
  isMyAlertsSelected = false;

  isMyAlertsTabFeatureToggleEnabled$ = this._featureToggleService.isFeatureEnabled('my-alerts').pipe(takeUntil(this._onDestroy$));
  isManageAlertsButtonDisplayed$ = combineLatest([this._featureToggleService.isFeatureEnabled('manage-alerts'), this.isAdmin$]).pipe(
    map(([isManageAlertsButtonFeatureToggleEnabled, isAdmin]) => isManageAlertsButtonFeatureToggleEnabled && isAdmin),
    takeUntil(this._onDestroy$)
  );

  /**
   * Waits until all tab-based feature toggle checks are finished before tab group
   * is loaded to ensure proper tab ordering
   *
   * Add any new tab-based feature toggles to the array in combineLatest(), and create
   * a new variable in order to show/hide the tab (e.g. isMyAlertsTabVisible)
   *
   * @returns Returns true at end of Observable chain to ensure that mat-tab-group is always shown
   */

  allTabsLoaded$: Observable<boolean> = combineLatest([this.isMyAlertsTabFeatureToggleEnabled$]).pipe(
    map(([isMyAlertsToggleEnabled]) => {
      this.isMyAlertsTabVisible = Boolean(isMyAlertsToggleEnabled);
      this.isMyAlertsSelected = Boolean(isMyAlertsToggleEnabled);
    }),
    map(() => {
      return true;
    }),
    takeUntil(this._onDestroy$)
  );
  isMyAlertsTabVisible = false;

  ngOnInit(): void {
    this._featureToggleService.initializeDevCycle();
    this._companyService.currentCompany$
      .pipe(
        filter((company) => !!company),
        concatMap((company) =>
          merge(this._populateAlertsStream$(company), this._populateMyAlertsStream$(company), this._populateUnreadMyAlertsStream$(company))
        ),
        take(1)
      )
      .subscribe();

    this._handleCompanyChange$().pipe(takeUntil(this._onDestroy$)).subscribe();
  }

  private _getAlertsFromApi$(selectedCompany: Company): Observable<Array<Alert>> {
    return this.activityAlertService.getAlerts$(this.selectedOptions, selectedCompany).pipe(
      map((alerts) => {
        const formattedAlerts = this._populateTimeAgoText(alerts.results);
        return formattedAlerts;
      })
    );
  }

  private _getMyAlertsFromApi$(selectedCompany: Company): Observable<Array<MyAlert>> {
    return this.myAlertService.getMyAlerts$(this.selectedOptions, selectedCompany).pipe(
      map((alerts) => {
        const formattedAlerts = this._populateTimeAgoTextForMyAlert(alerts.results);
        return formattedAlerts;
      })
    );
  }

  private _getUnreadMyAlertsFromApi$(selectedCompany: Company, reset = false): Observable<void> {
    return this.myAlertService.getUnreadMyAlerts$(selectedCompany, reset).pipe(
      map((unreadMyAlerts) => {
        this.isHideUnreadMyAlerts = unreadMyAlerts.newAlertCount > 0 ? false : true;
        unreadMyAlerts.newAlertCount = unreadMyAlerts.newAlertCount < 100 ? unreadMyAlerts.newAlertCount : '99+';
        this.unreadMyAlerts$.next(unreadMyAlerts);
      })
    );
  }

  public translateMyAlertToAlert(myAlert: MyAlert) {
    // get the date and time string in local format
    const timestamp = new Date(myAlert._source['@timestamp']);
    const get_local_timestamp = new Date(timestamp.getTime() - timestamp.getTimezoneOffset() * 60 * 1000);
    const dateTimeString = get_local_timestamp.toLocaleString('en-US', { timeZone: 'UTC' });
    
    const created_timestamp = new Date(myAlert._source.created);
    const get_local_created_timestamp = new Date(created_timestamp.getTime() - created_timestamp.getTimezoneOffset() * 60 * 1000);

    // split userProfileIds into 2 objects at position 1
    const sliceAt = 1;
    const userArr = Object.entries(myAlert._source.userProfileIds);
    const first_user_object = Object.fromEntries(userArr.slice(0, sliceAt));
    const remaining_user_object = Object.fromEntries(userArr.slice(sliceAt));

    const remaining_user_array = <string[]>Object.values(remaining_user_object);
    // get remaining recipient user
    const remaining_user = remaining_user_array.join('\n').trim();
    // get number of remaining recipient user
    const length_of_remaining_user = remaining_user_array.length;

    if (length_of_remaining_user == 0) {
      return {
        ...myAlert,
        _source: myAlert._source.source_event,
        first_user: first_user_object,
        timestamp: dateTimeString,
        created: get_local_created_timestamp
      };
    }

    return {
      ...myAlert,
      _source: myAlert._source.source_event,
      first_user: first_user_object,
      remaining_user: remaining_user,
      number_remaining_user: length_of_remaining_user,
      timestamp: dateTimeString,
      created: get_local_created_timestamp
    };
  }

  private _populateTimeAgoText(alerts: Array<Alert>) {
    const formattedAlerts = alerts.map((a) => {
      return { ...a, time_ago_text: getTimeAgoString(a._source.data.start_time, false),
        time_ago_source: formatDate(a._source.data.start_time)
       };
    });
    return formattedAlerts;
  }

  private _populateTimeAgoTextForMyAlert(alerts: Array<MyAlert>) {
    const formattedAlerts = alerts.map((a) => {
      return { ...a, time_ago_text: getTimeAgoString(a._source.created, false),
        time_ago_source: formatDate(a._source.created)
       };
    });
    return formattedAlerts;
  }

  private _populateAlertsStream$(company: Company): Observable<void> {
    this.isEndOfAlertsStream = false;
    return this._getAlertsFromApi$(company).pipe(
      map((alerts) => {
        if (alerts.length < this.MAX_NUMBER_OF_POSTS_FOR_AUTO_SCROLL_TRIGGER) {
          this.isEndOfAlertsStream = true;
        }
        this.allAlerts$.next(alerts);
        if (alerts.length === 0) {
          this._datadogService.newRumTiming('alerts_list_loaded_no_alerts');
        } else {
          this._datadogService.newRumTiming('alerts_list_loaded');
        }
      })
    );
  }

  private _populateMyAlertsStream$(company: Company): Observable<void> {
    this.isEndOfMyAlertStream = false;
    return this._getMyAlertsFromApi$(company).pipe(
      map((alerts) => {
        if (alerts.length < this.MAX_NUMBER_OF_POSTS_FOR_AUTO_SCROLL_TRIGGER) {
          this.isEndOfMyAlertStream = true;
        }
        this.allMyAlert$.next(alerts);
        if (alerts.length === 0) {
          this._datadogService.newRumTiming('my_alerts_list_loaded_no_alerts');
        } else {
          this._datadogService.newRumTiming('my_alerts_list_loaded');
        }
      })
    );
  }

  private _populateUnreadMyAlertsStream$(company: Company, reset = true): Observable<void> {
    return this._getUnreadMyAlertsFromApi$(company, reset);
  }

  private _handleCompanyChange$(): Observable<void> {
    return this._companyService.currentCompany$.pipe(
      skip(1),
      map((x) => cloneDeep(x)),
      distinctUntilChanged((prev, curr) => {
        return prev.value === curr.value;
      }),
      concatMap((company) => {
        this._clearFilters();
        this._clearAlertsStream();
        this._clearMyAlertStream();
        this._clearUnreadMyAlertsStream();
        return merge(
          this._populateAlertsStream$(company),
          this._populateMyAlertsStream$(company),
          this._populateUnreadMyAlertsStream$(company, this.isMyAlertsSelected)
        );
      })
    );
  }

  // TODO (AA-341): Fix this hack that relies on destroying the filter component, then
  // re-creating it. This will need to be replaced with a more dynamic
  // and robust solution.
  private _clearFilters() {
    this.isClearingFilters = true;
    setTimeout(() => (this.isClearingFilters = false));
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.unsubscribe();
  }

  selectedTabValue = (event) => {
    this.isMyAlertsSelected = false;
    switch (event.tab.textLabel) {
      case 'My Alerts':
        this.isMyAlertsSelected = true;
        this.fromMyAlert = 20;
        this.toMyAlert = 40;
        this._clearMyAlertStream();
        this.onMyAlertsChange(this.selectedOptions);
        this.readUnreadMyAlerts(this._companyService.currentCompany$.value, true);
        break;

      case 'All Alerts':
        this.from = 20;
        this.to = 40;
        this.onAlertsChange(this.selectedOptions);
        // AAG-235: Get new unread myAlerts number when click to any other tab
        this.readUnreadMyAlerts(this._companyService.currentCompany$.value);
        break;

      default:
        break;
    }
  };

  onScroll(): void {
    this._companyService.currentCompany$
      .pipe(
        concatMap((selectedCompany) => {
          return combineLatest([
            this.allAlerts$,
            this.activityAlertService.getScrollAlerts$(this.from, this.to, this.selectedOptions, selectedCompany)
          ]);
        }),
        take(1)
      )
      .subscribe(([currentAlerts, newAlerts]) => {
        if (newAlerts.results.length < this.NUMBER_OF_ADDITIONAL_POSTS_TO_LOAD) {
          this.isEndOfAlertsStream = true;
        }
        const combinedAlertsList = currentAlerts.concat(newAlerts.results);
        const formattedAlertsList = this._populateTimeAgoText(combinedAlertsList);
        this.allAlerts$.next(formattedAlertsList);
        this.from = this.to;
        this.to += this.NUMBER_OF_ADDITIONAL_POSTS_TO_LOAD;
      });
  }

  onMyAlertScroll(): void {
    this._companyService.currentCompany$
      .pipe(
        concatMap((selectedCompany) =>
          combineLatest(
            this.allMyAlert$,
            this.myAlertService.getMyAlerts$(this.selectedOptions, selectedCompany, {
              from_item: this.fromMyAlert,
              to_item: this.toMyAlert
            })
          )
        ),
        take(1)
      )
      .subscribe(([currentAlerts, newAlerts]) => {
        if (newAlerts.results.length < this.NUMBER_OF_ADDITIONAL_MY_ALERT_POSTS_TO_LOAD) {
          this.isEndOfMyAlertStream = true;
        }
        const combinedAlertsList = currentAlerts.concat(newAlerts.results);
        const formattedAlertsList = this._populateTimeAgoTextForMyAlert(combinedAlertsList);
        this.allMyAlert$.next(formattedAlertsList);
        this.fromMyAlert = this.to;
        this.toMyAlert += this.NUMBER_OF_ADDITIONAL_MY_ALERT_POSTS_TO_LOAD;
      });
  }

  private _clearAlertsStream(): void {
    this.allAlerts$.next(null);
  }

  private _clearMyAlertStream(): void {
    this.allMyAlert$.next(null);
  }

  private _clearUnreadMyAlertsStream(): void {
    this.isHideUnreadMyAlerts = true;
    this.unreadMyAlerts$.next(null);
  }

  onAlertsChange($event?) {
    this._companyService.currentCompany$
      .pipe(
        filter((company) => !!company),
        concatMap((company) => {
          const optionsParam = $event ?? undefined;
          this._clearAlertsStream();
          return this.activityAlertService.getAlerts$(optionsParam, company);
        }),
        map(
          (response) => {
            if (response.results.length < this.MAX_NUMBER_OF_POSTS_FOR_AUTO_SCROLL_TRIGGER) {
              this.isEndOfAlertsStream = true;
            }
            const formattedAlerts = this._populateTimeAgoText(response.results);
            this.allAlerts$.next(formattedAlerts);
          },
          (err) => {
            this.showErrorNotifications(err, this.handleActivityRetry);
          }
        ),
        take(1)
      )
      .subscribe();
  }

  onMyAlertsChange($event?) {
    this._companyService.currentCompany$
      .pipe(
        filter((company) => !!company),
        concatMap((company) => {
          const optionsParam = $event ?? undefined;
          this._clearMyAlertStream();
          return this.myAlertService.getMyAlerts$(optionsParam, company);
        }),
        map(
          (response) => {
            if (response.results.length < this.MAX_NUMBER_OF_POSTS_FOR_AUTO_SCROLL_TRIGGER) {
              this.isEndOfMyAlertStream = true;
            }
            const formattedAlerts = this._populateTimeAgoTextForMyAlert(response.results);
            this.allMyAlert$.next(formattedAlerts);
          },
          (err) => {
            this.showErrorNotifications(err, this.handleActivityRetry);
          }
        ),
        take(1)
      )
      .subscribe();
  }

  readUnreadMyAlerts(company: Company, reset = false) {
    this._getUnreadMyAlertsFromApi$(company, reset).subscribe();
  }

  onGetSelectedFilters($event) {
    if ($event) {
      Object.assign(this.selectedOptions, $event);
      this.onAlertsChange(this.selectedOptions);
      this.onMyAlertsChange(this.selectedOptions);
      this.from = 20;
      this.to = 40;
      this.fromMyAlert = 20;
      this.toMyAlert = 40;
    } else if ($event === '') {
      this.selectedOptions = undefined;
      this.onAlertsChange();
      this.onMyAlertsChange();
    } else {
      this.selectedOptions = undefined;
    }
  }

  handleActivityRetry = () => {
    this.onAlertsChange();
    this.onMyAlertsChange();
    // manually dismiss the notification after the button is clicked
    this._notifications.dismiss();
  };

  showErrorNotifications = (error, handler) => {
    const notificationText = ErrorCodes.ERROR_MSG.find((err) => {
      let errTitle = undefined;
      if (err.code === error.status) {
        errTitle = err;
      }
      return errTitle;
    });
    this.errorHandler(notificationText, error, handler);
  };

  errorHandler = (errorFormattedObj, error, handler) => {
    switch (error.status) {
      case 400:
        this._notifications.openError(errorFormattedObj.title, '', 10);
        break;
      case 500:
      case 501:
      case 502:
        this._notifications.openError(errorFormattedObj.title, '', 10, {
          onButtonClick: handler,
          buttonText: 'Retry!'
        });
        break;
      case 404:
        this._notifications.openError(errorFormattedObj.title, '', 10);
        break;
      case 401:
        this._notifications.openError(errorFormattedObj.title, '', 10);
        break;
      default:
        this._notifications.openError('Could not connect at the moment', '', 10);
        break;
    }
  };

  trackByFn(index) {
    return index;
  }

  redirectToAlertsManagement() {
    window.location.href = this.alertManagementBaseUrl;
  }

  redirectToMyAlerts() {
    window.location.href = this.alertManagementBaseUrl + '/myalerts';
  }
}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivityAlertsService } from '@services/activity-alerts.service';
import { Company } from 'src/app/models/company.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { catchError, debounceTime, distinctUntilChanged, filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, Subject, throwError, ReplaySubject } from 'rxjs';
import { cloneDeep, orderBy } from 'lodash';
import { DeviceDetectionService } from '@app/services/device-detection.service';
import { FiltersService } from '@app/services/filters.service';
import { ZuiDropdownOptions } from '@app/models';
import { CompanyStoreService } from '@app/services/company-store.service';

@Component({
  selector: 'app-filter-tags',
  templateUrl: './filter-tags.component.html',
  styleUrls: ['./filter-tags.component.scss']
})
export class FilterTagsComponent implements OnInit {
  private _onDestroy$: Subject<void> = new Subject<void>();
  constructor(
    public fb: FormBuilder,
    public activityAlertService: ActivityAlertsService,
    private _store: Store<AppState>,
    public device: DeviceDetectionService,
    public filtersService: FiltersService,
    private _companyService: CompanyStoreService
  ) {}
  public divisions = new FormControl();
  @Output() postSelectedDivision = new EventEmitter();
  @Output() selectedFilters = new EventEmitter();

  divisionList$ = new ReplaySubject<any[]>(1);

  public statusList: Array<ZuiDropdownOptions> = [
    {
      title: 'Open',
      value: 'open'
    },
    {
      title: 'Closed',
      value: 'closed'
    }
  ];
  public severityList: Array<ZuiDropdownOptions> = [
    {
      title: 'Critical',
      value: '1'
    },
    {
      title: 'Minor',
      value: '2'
    }
  ];

  selectedCompany: Company;
  public divisionList = [];

  onSelectedLocationFilter($event) {
    const divisions_data = $event
      .map((item) => {
        return item.value;
      })
      .join(',');
    this.selectedFilters.emit({ division: divisions_data });
  }

  onSelectedStatusFilter($event) {
    const statusData = $event
      .map((item) => {
        return item.value;
      })
      .join(',');
    this.selectedFilters.emit({ status: statusData });
  }

  onSelectedSeverityFilter($event) {
    const severityData = $event
      .map((item) => {
        return item.value;
      })
      .join(',');
    this.selectedFilters.emit({ severity: severityData });
  }

  private _getCurrentCompany$(): Observable<Company> {
    return this._companyService.currentCompany$.pipe(filter((company) => !!company));
  }

  private _handleCompanyChange$(): Observable<void> {
    return this._getCurrentCompany$().pipe(
      map((x) => cloneDeep(x)),
      distinctUntilChanged((prev, curr) => prev.value === curr.value),
      map((company) => {
        this.populateDivisionListOptions(company);
      })
    );
  }

  populateDivisionListOptions(selectedCompany: Company): void {
    this.activityAlertService
      .getAlerts$(undefined, selectedCompany)
      .pipe(
        switchMap((response) => {
          const orderedDivisions = orderBy(response.filters?.divisions || [], [(division) => division.name.toLowerCase()], ['asc']);
          const divisionOptions = orderedDivisions.map((division) => ({ title: division.name, value: division.id }));
          this.divisionList$.next(divisionOptions);
          return divisionOptions;
        }),
        catchError((err) => {
          return throwError(err);
        }),
        first()
      )
      .subscribe();
  }

  private _initializeDropdownFilters() {
    const defaultStatus = this.statusList.find((c) => c.title == 'Open');
    this.filtersService.filtersForm = this.fb.group({
      divisions: [null],
      status: [[defaultStatus.value]],
      severity: []
    });
    this.filtersService.hasInitialized = true;
  }

  ngOnInit(): void {
    if (!this.filtersService.hasInitialized) {
      this._initializeDropdownFilters();
    }
    this._handleCompanyChange$().pipe(debounceTime(5000), takeUntil(this._onDestroy$)).subscribe();
    this.filtersService.filtersForm.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe((val) => {
      this.filtersService.filtersForm.patchValue(
        {
          divisions: val.divisions,
          severity: val.severity,
          status: val.status
        },
        { emitEvent: false }
      );
    });
  }
}

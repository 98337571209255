import { Injectable } from '@angular/core';
import { Company } from '@app/models/company.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyStoreService {
  currentCompany$: BehaviorSubject<Company> = new BehaviorSubject<Company>(null);
}

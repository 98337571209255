import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivityAlertsService } from '@services/activity-alerts.service';
import { ReverseGeocoderService } from '@services/reverse-geocoder.service';
import { ReverseGeocoderResponse } from '../../models/reverse-geocoder-response.model';
import { formatDate, getAddressCityStateZipText, getCityStateZipText } from '@app/shared/utilities';
import { Company } from '../../models/company.model';
import { AppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { GOOGLE_MAPS_BASE_URI, INSTANT_EVENT_TYPE_VALUE } from '@shared/constants';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { DeviceDetectionService } from '@app/services/device-detection.service';
import { CompanyStoreService } from '@app/services/company-store.service';

@Component({
  selector: 'app-basic-alert',
  templateUrl: './basic-alert.component.html',
  styleUrls: ['./basic-alert.component.scss']
})
export class BasicAlertComponent implements OnInit {
  @Input() alertData;
  public alertLocation;
  public showId;
  googleMapsLinkText: string;
  googleMapsUri: string;
  public selectedCompany: Company;
  readonly INSTANT_EVENT_TYPE_VALUE = INSTANT_EVENT_TYPE_VALUE;
  reverseGeocode$;
  severityToIconMap = {
    1: 'error',
    2: 'warning'
  };
  formattedNotificationTimestamp;
  formattedAlertStartTimestamp;

  constructor(
    public activityAlertService: ActivityAlertsService,
    public httpClient: HttpClient,
    public reverseGeocoderService: ReverseGeocoderService,
    private store: Store<AppState>,
    public device: DeviceDetectionService,
    private _companyService: CompanyStoreService
  ) {
    this._companyService.currentCompany$.subscribe((selectedCompany) => {
      this.selectedCompany = selectedCompany;
    });
  }

  ngOnInit(): void {
    this.alertLocation = this.alertData?._source?.data.location;
    this.formattedNotificationTimestamp = formatDate(this.alertData.timestamp);

    this.reverseGeocode$ = this._getReverseGeocode$(this.alertLocation).pipe(
      map((address: ReverseGeocoderResponse) => {
        return this._buildGoogleMapsInfo(address);
      }),
      catchError(() => {
        return throwError('Failed to get location. Try closing and re-opening this section to retry.');
      }),
      shareReplay(1)
    );
  }

  private _buildGoogleMapsInfo(geocodeAddress) {
    const googleMapsLinkText = this._getGoogleMapsLinkText(geocodeAddress);
    const geocodeData = geocodeAddress.data && geocodeAddress.data[0];
    const googleMapUri = this._getGoogleMapsUri(geocodeData ? googleMapsLinkText : geocodeAddress);
    return {
      text: googleMapsLinkText,
      uri: googleMapUri
    };
  }

  private _getReverseGeocode$(location) {
    return this.reverseGeocoderService.getReverseGeocode(location?.latitude, location?.longitude);
  }

  private _getGoogleMapsUri(geoCodeAddress) {
    const locationQuery =
      geoCodeAddress.latitude && geoCodeAddress.longitude ? `${geoCodeAddress.latitude},${geoCodeAddress.longitude}` : geoCodeAddress;
    return GOOGLE_MAPS_BASE_URI + locationQuery;
  }

  private _getGoogleMapsLinkText(geocode) {
    const geocodeData = geocode.data && geocode.data[0];
    if (geocodeData) {
      return geocodeData.address ? getAddressCityStateZipText(geocodeData) : getCityStateZipText(geocodeData);
    } else {
      return 'View in Google Maps';
    }
  }

  onCurrentLocation(source) {
    try {
      const url = environment.zonarApps.gtcxMapsBaseUrl + '/assets/' + source.context.entity_ids.asset_id + '/live';
      window.open(url, '_self');
    } catch (error) {
      console.log('Could not open Zonar map for asset');
    }
  }
}

import { Injectable } from '@angular/core';
import { User } from '@auth0/auth0-spa-js';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { statusType } from '@datadog/browser-logs/bundle/datadog-logs';
import { environment } from '../../environments/environment';
import { appName, appVersion } from '../../environments/shared';
import { PermissionsService } from '@zonar-ui/auth';
import { first } from 'rxjs/operators';
import { isLocalDevEnv } from '../shared/utilities/utilities';
@Injectable({
  providedIn: 'root'
})
export class DatadogService {
  private user: User;
  constructor(private permissions: PermissionsService) {
    if (!isLocalDevEnv()) {
      // enable real user monitoring for all non-local (deployed) environments
      datadogRum.init({
        applicationId: environment.datadog.applicationId,
        clientToken: environment.datadog.clientToken,
        env: environment.name,
        service: appName,
        version: appVersion,
        sessionSampleRate: 100,
        premiumSampleRate: 100,
        replaySampleRate: 100,
        silentMultipleInit: true,
        trackUserInteractions: true,
        allowedTracingOrigins: [/https:\/\/.*\.zonarsystems\.net/, `${environment.apiBase}/geocoder/api/v1/reverse`],
        site: environment.datadog.siteUrl,
        useSecureSessionCookie: true,
        useCrossSiteSessionCookie: true,
        defaultPrivacyLevel: 'mask-user-input'
      });

      datadogLogs.init({
        clientToken: environment.datadog.clientToken,
        env: environment.name,
        service: appName,
        silentMultipleInit: true,
        site: environment.datadog.siteUrl,
        forwardErrorsToLogs: false,
        sampleRate: 100
      });

      this.permissions
        .getUsers()
        .pipe(first((users) => Boolean(users?.length)))
        .subscribe((user) => {
          this.user = user[0];
          datadogRum.setUser({
            id: this.user.id,
            email: this.user.email,
            sub: this.user.sub
          });
        });
    }
  }

  addRumAction(name: string, context?: object | undefined): void {
    if (!isLocalDevEnv()) {
      datadogRum.addAction(name, context);
    }
  }

  addRumError(error: Error, context?: object | undefined): void {
    if (!isLocalDevEnv()) {
      datadogRum.addError(error, context);
    }
  }

  addRumTiming(name: string, time?: number | undefined): void {
    if (!isLocalDevEnv()) {
      datadogRum.addTiming(name, time || Date.now());
    }
  }

  /**
   * Sends a default RUM timing, or creates a RumTiming object which can be used for more precise measuring.
   * @param name The name of the timing
   * @param useView If true, sends a one-off RUM timing which counts from start time of the current RUM view.
   * If false, returns a RumTiming object which can be passed to sendRumTiming.
   * Defaults to true.
   * https://docs.datadoghq.com/real_user_monitoring/browser/monitoring_page_performance/#add-your-own-performance-timing
   */
  newRumTiming(name: string, useView = true): RumTiming {
    if (!isLocalDevEnv()) {
      if (useView) {
        datadogRum.addTiming(name);
        return;
      }
      // Note: performance uses its own timestamp internally. However, we use the current epoch as a unique identifier.
      const markName = Date.now().toString();
      performance.mark(markName);
      return {
        name: name,
        markName: markName
      };
    }
  }

  /**
   * Sends a timing (as a RUM action), starting from when the RumTiming was created.
   * The RumTiming is not preserved, so additional timings will need their own unique RumTiming.
   * @param timing The RumTiming object to measure from
   */
  sendRumTiming(timing: RumTiming): void {
    if (!isLocalDevEnv()) {
      const measureName = Date.now().toString();
      try {
        performance.measure(measureName, timing.markName);
        datadogRum.addAction(timing.name, {
          [`${timing.name}_timing`]: performance.getEntriesByName(measureName)[0].duration
        });
        performance.clearMarks(timing.markName);
        performance.clearMeasures(measureName);
      } catch (error) {
        // if we get an error (such as because a mark doesn't exist), only throw if we are local,
        // otherwise the error will show in console errors in RUM on datadog
        // (note you would need to temporarily remove the !isLocalDevEnv check from methods to expose this)
        if (isLocalDevEnv()) throw error;
      }
    }
  }

  getRumUser(): User | undefined {
    return this.user;
  }

  startSessionReplayRecording(): void {
    datadogRum.startSessionReplayRecording();
  }

  stopSessionReplayRecording(): void {
    datadogRum.stopSessionReplayRecording();
  }

  log(message: string, attributes, status: statusType = 'info'): void {
    if (!isLocalDevEnv()) {
      datadogLogs.logger.log(message, attributes, status);
    }
  }
}

export interface RumTiming {
  name: string;
  markName: string;
}

import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import 'moment-duration-format';
import { ReverseGeocoderData } from 'src/app/models/reverse-geocoder-response.model';

export const getTimeAgoString = (timestamp: string, verbose: boolean): string => {
  if (!timestamp) {
    return '';
  }

  const now = moment.utc();
  const eventTs = moment(timestamp);
  const minutesAgo = now.diff(eventTs, 'minutes');
  const hoursAgo = now.diff(eventTs, 'hours');
  const daysAgo = now.diff(eventTs, 'days');
  const weeksAgo = now.diff(eventTs, 'weeks');
  const monthsAgo = now.diff(eventTs, 'months');
  const yearsAgo = now.diff(eventTs, 'years');
  let isPluralUnits = false;
  if (yearsAgo > 0) {
    isPluralUnits = yearsAgo !== 1;
    return `${verbose ? 'Updated ' : ''}${yearsAgo} ${isPluralUnits ? 'years' : 'year'} ago`;
  }

  if (monthsAgo > 0) {
    isPluralUnits = monthsAgo !== 1;
    return `${verbose ? 'Updated ' : ''}${monthsAgo} ${isPluralUnits ? 'months' : 'month'} ago`;
  }
  if (weeksAgo > 0) {
    isPluralUnits = weeksAgo !== 1;
    return `${verbose ? 'Updated ' : ''}${weeksAgo} ${isPluralUnits ? 'weeks' : 'week'} ago`;
  }
  if (daysAgo > 0) {
    isPluralUnits = daysAgo !== 1;
    return `${verbose ? 'Updated ' : ''}${daysAgo} ${isPluralUnits ? 'days' : 'day'} ago`;
  }
  if (hoursAgo > 0) {
    isPluralUnits = hoursAgo !== 1;
    return `${verbose ? 'Updated ' : ''}${hoursAgo} ${isPluralUnits ? 'hours' : 'hour'} ago`;
  }
  if (minutesAgo <= 0) {
    return verbose ? 'Updated just now' : 'Just now';
  }
  if (minutesAgo > 0) {
    isPluralUnits = minutesAgo !== 1;
    return `${verbose ? 'Updated ' : ''}${minutesAgo} ${isPluralUnits ? 'minutes' : 'minute'} ago`;
  }
};
export const getStateZipText = (geocodeData: ReverseGeocoderData): string => {
  return `${geocodeData.district} ${geocodeData.zipcode}`;
};

export const getCityStateZipText = (geocodeData: ReverseGeocoderData): string => {
  return geocodeData.locality
    ? `${geocodeData.locality ?? ''}, ${geocodeData?.district ?? ''} ${geocodeData.zipcode ?? ''}`
    : getStateZipText(geocodeData);
};

export const getAddressCityStateZipText = (geocodeData: ReverseGeocoderData): string => {
  return geocodeData.locality
    ? `${geocodeData.address ?? ''}, ${geocodeData.locality ?? ''}, ${geocodeData.district ?? ''} ${geocodeData.zipcode ?? ''}`
    : getStateZipText(geocodeData);
};

export const formatDate = (date): string => {
  return moment(date).format('MMM DD, YYYY HH:mm:ss') + ' ' + moment().tz(momentTimezone.tz.guess()).format('z');
};

// Source: https://stackoverflow.com/a/53943624
export function repeatTimes(max: number) {
  return {
    [Symbol.iterator]: function* () {
      // eslint-disable-next-line
      for (let i = 0; i < max; i++, yield) {}
    }
  };
}

export const isLocalDevEnv = () => {
  return window?.location?.origin?.includes('localhost:4200');
};

export const isBoolean = (val) => {
  return typeof val === 'boolean';
};

import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ZonarUICoreModule } from '@zonar-ui/core';
import { ZonarUiProgressSpinnerModule } from '@zonar-ui/progress-spinner';
import { CoreModule } from 'src/app/modules/core/core.module';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ActivityFeedComponent } from './components/activity-feed/activity-feed.component';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { FilterTagsComponent } from './components/filter-tags/filter-tags.component';
import { MatChipsModule } from '@angular/material/chips';
import { HomePageComponent } from './pages/home/home.page.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { BasicAlertComponent } from './components/basic-alert/basic-alert.component';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { ZonarUiI18nModule, TranslateModule } from '@zonar-ui/i18n';
import { ZonarUiFooterModule } from '@zonar-ui/footer';
import { SidenavModule } from '@zonar-ui/sidenav';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { ZonarUiSearchableDropdownModule } from '@zonar-ui/searchable-dropdown';
import { CustomUnitConversionPipe } from './shared/pipes/custom-unit-conversion.pipe';
import { DatadogService } from './services/datadog.service';
import { WithLoadingPipe } from '@shared/pipes/with-loading.pipe';
import { ENV, getEnv } from '@environments/environment.provider';
import { MobileDrawerComponent } from './components/mobile-drawer/mobile-drawer.component';

@NgModule({
  declarations: [
    AppComponent,
    ActivityFeedComponent,
    FilterTagsComponent,
    HomePageComponent,
    BasicAlertComponent,
    SkeletonLoaderComponent,
    CustomUnitConversionPipe,
    WithLoadingPipe
  ],
  imports: [
    AppRoutingModule,
    AuthModule.forRoot({
      clientId: environment.auth.clientID,
      domain: environment.auth.domain,
      audience: environment.auth.audience,
      redirect_uri: window.location.origin,
      scope: 'openid profile email',
      useRefreshTokens: true,
      max_age: 36000,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [
          // `${environment.apiBase.url}/*`,
          `${environment.coreEntityApiBase.url}/*`,
          // `${environment.locationApi.url}/*`,
          // `${environment.pathApiBase.url}/*`,
          `${environment.apiBase}/*`,
          `${environment.userPreferenceApiBase.url}/*`,
          `${environment.coreEntityApiBase.url}beta2/*`
        ]
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    ZonarUICoreModule,
    FlexLayoutModule,
    ZonarUiProgressSpinnerModule,
    ZonarUiFooterModule,
    HttpClientModule,
    MatCardModule,
    MatGridListModule,
    MatDividerModule,
    MatListModule,
    MatButtonModule,
    MatBadgeModule,
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MobileDrawerComponent,
    ZonarUIMaterialModule,
    CdkAccordionModule,
    InfiniteScrollModule,
    AuthModule,
    SidenavModule.forRoot({ stage: environment.authEnv }),
    NgxSkeletonLoaderModule,
    ZonarUiSearchableDropdownModule,
    ZonarUiI18nModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en-US'
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: 'applicationId', useValue: environment.auth.applicationId },
    {
      provide: 'supportedLanguages',
      useValue: environment.i18n.supportedLanguages
    },
    { provide: 'defaultLanguage', useValue: environment.i18n.defaultLanguage },
    CustomUnitConversionPipe,
    DatadogService,
    { provide: ENV, useFactory: getEnv }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

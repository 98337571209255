import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  constructor(private fb: FormBuilder) {}

  hasInitialized = false;
  filtersForm: FormGroup = this.fb.group({});
}
